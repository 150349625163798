.Container
{background-color: rgb(207, 169, 123);
    
    position:relative;
    width: 100%;
    height: 1605px;
    overflow: hidden;
   
}
.Content
{
  
  top: -300px;
    position:absolute;
    width: 100%;
    height: 2300px;
    overflow: hidden;
}
  /*----------awrapper------------*/
  @media screen and (max-width: 768px) {
     .container {
      flex-direction: column-reverse;
    }
   .row {
      width: 100%;
    }
    .awrapper {
      height: 50vh;
    }

    .Container
{background-color: rgb(207, 169, 123);
   
    height: 800px;
   
}
.Content
{
  
  
    height: 1300px;
   
}

  }
  