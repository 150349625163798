.hero {
  background-image: url("../../../../public/images/frontcover.png");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #180202;
}
.top
{
  background-color: rgb(255, 255, 255);
  margin: 200;
  z-index: 2;
  position:relative;
  color: whitesmoke;
}
h1, h2{
  color: #fff;
  font-weight: bold;
}
p{
  color: #dcd7d7;
}
.button
{
  cursor: pointer;
}
.hero .container .row .primary-btn1
{
  color: black;
 cursor:grab;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    
    background-position: right;
    padding-top: 10%;
    height: 100vh;
    width: 800px;
  }
  .hero .row {
    width: 100%;
  }

}
